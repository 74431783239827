<template>
    <a-card
        :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}"
    >
        <template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Select Actions</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                </a-col>
			</a-row>
		</template>
        <a-checkbox-group @change="productSelected" style="display:inline">
        <a-table :columns="actioncolumns" :data-source="actions" :pagination="{position: 'bottom',pageSize:8}">
            <template slot="selectorColumn" slot-scope="row">
				<a-checkbox :value="row"></a-checkbox>
			</template>
        </a-table>
        </a-checkbox-group>
        <a-button shape="round" @click="handleSelect" type="primary" style="margin-left:10px">OK</a-button>
        <a-button shape="round" @click="cancel" type="secondary" style="margin-left:10px">Cancel</a-button>

    </a-card>
</template>

<script>
import {listaction, modifyactions} from '@/api/action';


export default ({
    data() {
        return {
            q:'',
            actions: [],
            selectedActions:[],
            actioncolumns:[
                {
                    title:"", 
                    dataIndex:'actionid',
                    scopedSlots: { customRender: 'selectorColumn' },
                    width: 50,
                },
                {title:"Name", dataIndex:'name'},
                {title:'Action Id', dataIndex:'actionid'},
                {title:'Action taker', dataIndex:'actionto'},
                {title:'Type', dataIndex:'actiontype'},
            ]
        };        
    },
    props:{
        goalid:String
    },
    mounted(){
        listaction().then(data=>{
            this.actions = data;
        });
    },
    methods:{
        productSelected(values){
            this.selectedActions = values;
        },
        cancel(){
            this.$router.go(-1);
        },
        // function: goal-12
        handleSelect(){
            modifyactions({goalid:this.goalid, actions:this.selectedActions}).then(data=>{
                this.$router.go(-1);
            })
        },
    }
})
</script>
